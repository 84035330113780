import React, { Component } from "react";
import { useEffect } from "react";
import Badge from "react-bootstrap/Badge";
import Stack from "react-bootstrap/Stack";

//import "./infobar.css";
import "./ioname.css";
import { Accordion, Col, Row, Form, Button } from "react-bootstrap";
import { Padding } from "@mui/icons-material";

interface MyState {
  nameVisible: boolean;
  outputNameChecked: boolean;
  outputNameClearChecked: boolean;
  aantalIos: number;
  outpName: string;
}

interface MyProps {
  onNameChange: (naam: string) => any;
  onAantalChange: (aantal: number) => any;
  onEigenNaamCheckChange: (check: boolean) => any;
  onUitgangNaamCheckChange: (check: boolean) => any;
  onRuimtenNaamCheckChange: (check: boolean) => any;
}

class IoName extends Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
    this.state = { outpName: "", nameVisible: false, outputNameChecked: true, outputNameClearChecked: true, aantalIos: 1 };
  }

  componentDidUpdate(
    prevProps: Readonly<MyProps>,
    prevState: Readonly<MyState>,
    snapshot?: any
  ): void {
    

    if (prevState.outpName === this.state.outpName && prevState.aantalIos === this.state.aantalIos ) {
      if (this.state.outputNameClearChecked == true) {
        //debugger
        if (this.state.outpName !== "") {
        this.setState({ outpName: "" });
        this.props.onNameChange("");
        }
      }
      if (this.state.aantalIos !== 1) {
        this.setState({ aantalIos: 1 });
        this.props.onAantalChange(1);
      }
    }
  }
  //naamText = "";
  onNaamChange = (event: any) => {
    //console.log(event.target.value)
    const naamText = event.target.value;
    this.props.onNameChange(naamText);
    this.setState({outpName: naamText})
  };

  onAantalChange1 = (event: any) => {
    //debugger
    const aantal = Number(event.target.value);
    this.setState({ aantalIos: aantal });
    this.props.onAantalChange(aantal);
  };

  //nameVisible = false;
  onEigenNaamCheckChange = (event: any) => {
    //console.log(event.target.checked);
    this.props.onEigenNaamCheckChange(event.target.checked);
    this.setState({ nameVisible: event.target.checked });
  };

  onUitgangNaamCheckChange = (event: any) => {
    //console.log(event.target.checked);
    this.props.onUitgangNaamCheckChange(event.target.checked);
    this.setState({ outputNameChecked: event.target.checked });
  };

  onUitgangNaamClearCheckChange = (event: any) => {
    //console.log(event.target.checked);
    this.props.onUitgangNaamCheckChange(event.target.checked);
    this.setState({ outputNameClearChecked: event.target.checked });
  };

  onRuimtenNaamCheckChange = (event: any) => {
    //console.log(event.target.checked);
    this.props.onRuimtenNaamCheckChange(event.target.checked);
    //this.setState({ ruimteNaamCheck: event.target.checked });

  };

  outputsUp = () => {
    let aant = this.state.aantalIos + 1;
    this.setState({aantalIos: aant})
    this.props.onAantalChange(aant);
  };

  outputsDown = () => {
    let aant = this.state.aantalIos - 1;
    if (aant ==0) {
      aant = 1
    }
    this.setState({aantalIos: aant})
    this.props.onAantalChange(aant);
  };

  render() {
    return (
      <div className="ionames">
        <Form.Group>
          <Row>
            <Col>
              <Form.Label>Naam</Form.Label>
              <Form.Control
                id="ruimtenaam"
                onChange={this.onNaamChange}
                style={{ width: "250px" }}
                value={this.state.outpName}
              />
            </Col>

            <Col>
              <Form.Label>Aantal</Form.Label>
              <Form.Control
                defaultValue={1}
                id="aantalOutputs"
                onChange={this.onAantalChange1}
                style={{ width: "50px" }}
                value={this.state.aantalIos}
              />

              
            </Col>

            <Col>
            <Button className="btnUp" onClick={this.outputsUp}> +</Button>
            <Button className="btnUp" onClick={this.outputsDown}> -</Button>
            </Col>
            
            
            
          </Row>
          <Row>
            <Col  md="auto">
              <Form.Check type="checkbox" id="check-api-checkbox2" >
                <Form.Check.Input
                  type="checkbox"
                  name="ruimtenaam"
                  onChange={this.onRuimtenNaamCheckChange}
                />
                <Form.Check.Label className="label" >Ruimte</Form.Check.Label>
              </Form.Check>
            </Col>
            <Col  md="auto">
              <Form.Check type="checkbox" id="check-api-checkbox">
                <Form.Check.Input
                  checked={this.state.outputNameChecked}
                  type="checkbox"
                  name="uitgangnaam"
                  onChange={this.onUitgangNaamCheckChange}
                />
                <Form.Check.Label className="label">Uitgang</Form.Check.Label>
              </Form.Check>
            </Col>

            <Col  md="auto">
              <Form.Check type="checkbox" id="check-api-checkbox3">
                <Form.Check.Input
                  checked={this.state.outputNameClearChecked}
                  type="checkbox"
                  name="uitgangnaamclear"
                  onChange={this.onUitgangNaamClearCheckChange}
                />
                <Form.Check.Label className="label">Maak naam leeg</Form.Check.Label>
              </Form.Check>
            </Col>
          </Row>
        </Form.Group>
      </div>
    );
  }
}
export default IoName;
