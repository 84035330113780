import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { useSpring, animated } from "@react-spring/web";
import { TransitionProps } from "@mui/material/transitions";
import Collapse from "@mui/material/Collapse";
import { alpha, styled } from "@mui/material/styles";
import {
  TreeItem,
  TreeItemProps,
  treeItemClasses,
} from "@mui/x-tree-view/TreeItem";
//import { Icon } from '@fortawesome/fontawesome-svg-core';
import { Icon } from "@mui/material";

import {
  SensIcon,
  DimmerIcon,
  RelaisIcon,
  BtnIcon,
  FloorIcon,
  InputIcon,
  MotionIcon,
  TempIcon,
  TouchIcon,
  FanIcon,
  RolIcon,
  RolIcon2,
  CtdIcon,
  ElectricIcon,
  HanddoekIcon,
  StopcontactkIcon,
  SunIcon,
  EnergyIcon,
  BelIcon,
  SWC04Icon,
} from "./svgIcons";

export interface kamerItem {
  id: string;
  name: string;
  key: string;
  icon: any;
  expanded: boolean;
  children?: kamerItem[];
}

export interface parentModule {
  id: number,
  mod: kamerItem
}

export interface kamerArray {
  item: kamerItem;
}

export interface testdata1 {
  nodeid: string;
  label: string;
  type: string;
  parent: string;
  typeIcon: string;
  moduleType: string;
}

export interface woningArray {
  woningArray: Array<testdata1>;
}

export interface ios {
  nodeid: string;
  label: string;
  type: string;
  parent: string;
  typeIcon: string;
  moduleType: string;
}

export interface ioArray {
  ios: Array<ios>;
}

export interface OutputModules {
  nodeid: string;
  label: string;
  type: string;
  parent: string;
  typeIcon: string;
  moduleType: string;
}

export const DimmerTreeIcon: any = () => {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
    >
      {/* tslint:disable-next-line: max-line-length */}

      <path d="M9 21c0 .5.4 1 1 1h4c.6 0 1-.5 1-1v-1H9v1zm3-19C8.1 2 5 5.1 5 9c0 2.4 1.2 4.5 3 5.7V17c0 .5.4 1 1 1h6c.6 0 1-.5 1-1v-2.3c1.8-1.3 3-3.4 3-5.7 0-3.9-3.1-7-7-7z" />
    </SvgIcon>
  );
};

export const Lightning: any = () => {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
    >
      {/* tslint:disable-next-line: max-line-length */}

      <path d="M23.5 13.187h-7.5v-12.187l-7.5 17.813h7.5v12.187l7.5-17.813z" />
    </SvgIcon>
  );
};

export const RelaisTreeItem: any = () => {
  return (
    <svg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
      <path
        style={{
          stroke: "rgb(0, 0, 0)",
          fill: "none",
          strokeWidth: 18,
        }}
        d="M 337.83 178.1 L 117.394 248.708 L 30.712 249.282"
      />
      <line
        style={{
          stroke: "rgb(0, 0, 0)",
          fill: "none",
          strokeWidth: 18,
        }}
        x1={307.979}
        y1={252.727}
        x2={456.659}
        y2={251.579}
      />
    </svg>
  );
};

export const RolreeItem: any = () => {
  return (
    <svg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
      <path
        style={{
          stroke: "rgb(0, 0, 0)",
          fill: "none",
          strokeWidth: 18,
        }}
        d="M 337.83 178.1 L 117.394 248.708 L 30.712 249.282"
      />
      <line
        style={{
          stroke: "rgb(0, 0, 0)",
          fill: "none",
          strokeWidth: 18,
        }}
        x1={307.979}
        y1={252.727}
        x2={456.659}
        y2={251.579}
      />
    </svg>
  );
};

export const FloorTreeItem: any = () => (
  <svg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
    <polygon
      style={{
        stroke: "rgb(0, 0, 0)",
        fill: "none",
        strokeWidth: 18,
      }}
      points="62.912 52.134 62.912 465.177 199.934 465.177 198.617 266.889 442.358 267.548 441.7 51.475"
    />
  </svg>
);

export function MinusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

export function PlusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

export function CloseSquare(props: SvgIconProps) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

export function TransitionComponent(props: TransitionProps) {
  const style = useSpring({
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

export const CustomTreeItem = React.forwardRef(
  (props: TreeItemProps, ref: React.Ref<HTMLLIElement>) => (
    <TreeItem {...props} TransitionComponent={TransitionComponent} ref={ref} />
  )
);

export const StyledTreeItem = styled(CustomTreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

// Used in kastopbouw
export const getTreeIconByDbType = (dbType: string) => {
  let icon = null;
  //debugger
  switch (dbType) {
    case "dimmer":
      icon = (
        <Icon key={dbType}>
          <DimmerIcon />
        </Icon>
      );
      break;
    case "DMX":
      icon = (
        <Icon key={dbType}>
          <DimmerIcon />
        </Icon>
      );
      break;
  
    case "relais":
      icon = (
        <Icon key={dbType}>
          <RelaisIcon />
        </Icon>
      );
      break;

    case "btn":
      icon = (
        <Icon key={dbType}>
          <BtnIcon />
        </Icon>
      );
      break;

      case "swc04":
      icon = (
        <Icon key={dbType}>
          <SWC04Icon />
        </Icon>
      );
      break;

    case "category":
      icon = (
        <Icon key={dbType}>
          <FloorIcon />
        </Icon>
      );
      break;
    case "floor":
      icon = (
        <Icon key={dbType}>
          <FloorIcon />
        </Icon>
      );
      break;

    case "sen":
      icon = (
        <Icon key={dbType}>
          <SensIcon />
        </Icon>
      );
      break;
    case "inp":
      icon = (
        <Icon key={dbType}>
          <InputIcon />
        </Icon>
      );
      break;
    case "mot":
      icon = (
        <Icon key={dbType}>
          <MotionIcon />
        </Icon>
      );
      break;

    case "temp":
      icon = (
        <Icon key={dbType}>
          <TempIcon />
        </Icon>
      );
      break;
    case "touch":
      icon = (
        <Icon key={dbType}>
          <TouchIcon />
        </Icon>
      );
      break;

    case "rol":
      icon = (
        // Set key to ROL ipv REL !!!!!!!!!!
        <Icon key={"ROL"}>
          <RolIcon />
        </Icon>
      );
      break;
    case "rol2":
      icon = (
        // Set key to ROL ipv REL !!!!!!!!!!

        <Icon key={dbType}>
          <RolIcon2 />
        </Icon>
      );
      break;

    case "electric":
      icon = (
        <Icon key={dbType}>
          <ElectricIcon />
        </Icon>
      );
      break;

    case "ctd":
      icon = (
        <Icon key={dbType}>
          <CtdIcon />
        </Icon>
      );
      break;

    case "FAN":
      icon = (
        <Icon key={dbType}>
          <FanIcon />
        </Icon>
      );
      break;

    case "fan":
      icon = (
        <Icon key={dbType}>
          <FanIcon />
        </Icon>
      );
      break;

    case "stopc":
      icon = (
        <Icon key={dbType}>
          <StopcontactkIcon />
        </Icon>
      );
      break;

    case "hand":
      icon = (
        <Icon key={dbType}>
          <HanddoekIcon />
        </Icon>
      );
      break;

    case "sun":
      icon = (
        <Icon key={dbType}>
          <SunIcon />
        </Icon>
      );
      break;

    case "energy":
      icon = (
        <Icon key={dbType}>
          <EnergyIcon />
        </Icon>
      );
      break;

    case "bel":
      icon = (
        <Icon key={dbType}>
          <BelIcon />
        </Icon>
      );
      break;

    //return FloorTreeItem()
  }

  return icon;
};

// Used in 
//  * Opbouwfunctions (2x)
//   * Kastopbouw (9x)
//  * Overzicht (2x)
export const getTreeIcon2 = (iconType: string, qType: string) => {
  let icon = null;
  let type = "";

  if (iconType) {
    if (iconType.includes("BTN")) {
      type = "btn";
    } else if (iconType.includes("DMX")) {
      type = "dimmer";
    } else {
      type = iconType;
    }

    switch (type) {
      case "dimmer":
        icon = (
          <Icon key={qType}>
            <DimmerIcon />
          </Icon>
        );
        break;

      case "relais":
        icon = (
          <Icon key={qType}>
            <RelaisIcon />
          </Icon>
        );
        break;

      case "btn":
        icon = (
          <Icon key={qType}>
            <BtnIcon />
          </Icon>
        );
        break;

        case "swc04":
          icon = (
            <Icon key={qType}>
              <SWC04Icon />
            </Icon>
          );
          break;

      case "category":
        icon = (
          <Icon key={qType}>
            <FloorIcon />
          </Icon>
        );
        break;
      case "floor":
        icon = (
          <Icon key={qType}>
            <FloorIcon />
          </Icon>
        );
        break;

      case "sen":
        icon = (
          <Icon key={qType}>
            <SensIcon />
          </Icon>
        );
        break;
      case "inp":
        icon = (
          <Icon key={qType}>
            <InputIcon />
          </Icon>
        );
        break;
      case "mot":
        icon = (
          <Icon key={qType}>
            <MotionIcon />
          </Icon>
        );
        break;

      case "temp":
        icon = (
          <Icon key={qType}>
            <TempIcon />
          </Icon>
        );
        break;
      case "touch":
        icon = (
          <Icon key={qType}>
            <TouchIcon />
          </Icon>
        );
        break;

      case "rol":
        icon = (
          // Set key to ROL ipv REL !!!!!!!!!!
          <Icon key={"ROL"}>
            <RolIcon />
          </Icon>
        );
        break;
      case "rol2":
        icon = (
          // Set key to ROL ipv REL !!!!!!!!!!

          <Icon key={qType}>
            <RolIcon2 />
          </Icon>
        );
        break;

      case "electric":
        icon = (
          <Icon key={qType}>
            <ElectricIcon />
          </Icon>
        );
        break;

      case "ctd":
        icon = (
          <Icon key={qType}>
            <CtdIcon />
          </Icon>
        );
        break;

      case "FAN":
        icon = (
          <Icon key={qType}>
            <FanIcon />
          </Icon>
        );
        break;

      case "fan":
        icon = (
          <Icon key={qType}>
            <FanIcon />
          </Icon>
        );
        break;

      case "stopc":
        icon = (
          <Icon key={qType}>
            <StopcontactkIcon />
          </Icon>
        );
        break;

      case "hand":
        icon = (
          <Icon key={qType}>
            <HanddoekIcon />
          </Icon>
        );
        break;

      case "sun":
        icon = (
          <Icon key={qType}>
            <SunIcon />
          </Icon>
        );
        break;

      case "energy":
        icon = (
          <Icon key={qType}>
            <EnergyIcon />
          </Icon>
        );
        break;

      case "bel":
        icon = (
          <Icon key={qType}>
            <BelIcon />
          </Icon>
        );
        break;

      //return FloorTreeItem()
    }

    return icon;
  }

  return null;

  /*
    if (iconType === "dimmer") {
      const dimmerIcon:any =  (
        <Icon key = "DIM">
          <DimmerIcon />
        </Icon>
    )
     return dimmerIcon
    } else if (iconType === "relais") {
      const relaisIcon:any =  (
        <Icon key = "REL">
          <RelaisIcon />
        </Icon>
      
    )
     return relaisIcon
    } else if (iconType === "floor") {
     return FloorTreeItem()
    }
    */
};

function isUitgangInKamer(
  uitgangen: testdata1[],
  parentid: string,
  children: kamerItem[]
) {
  let uitgangenInKamer: kamerItem[] = [];

  // TODO: Change to filter
  uitgangen.map((uitgang) => {
    if (uitgang.parent === parentid) {
      //console.log(uitgang)
      const data: kamerItem = {
        id: uitgang.nodeid,
        name: uitgang.label,
        key: uitgang.nodeid,
        expanded: true,
        //icon: getTreeIcon(uitgang.moduleType, uitgang.type),
        icon: getTreeIcon2(uitgang.typeIcon, uitgang.type),
        children: children,
      };

      uitgangenInKamer.push(data);
    }
    //return uitgangenInKamer
  });
  return uitgangenInKamer;
}

function voegToe(
  items: kamerItem[],
  parentItems: kamerItem[],
  uitgangen: testdata1[]
) {
  items.map((item) => {
    let uitgangeninkamer = isUitgangInKamer(uitgangen, item.id, []);
    const items: kamerItem = {
      id: item.id,
      name: item.name,
      key: item.key,
      expanded: true,
      icon: item.icon,
      children: uitgangeninkamer,
    };
    parentItems.push(items);
  });
  return parentItems;
}

export const itemArray = (items: kamerItem) => {
  let retArray: string[] = [];

  retArray.push(items.id);

  const addArray = (nodes: kamerItem) =>
    nodes.children?.map((item) => {
      retArray.push(item.id);
      if (item.children && item.children?.length >> 0) {
        addArray(item);
      }
    });

  addArray(items);
  return retArray;
};

export const ioItemArray = (items: OutputModules[]) => {
  let retArray: string[] = [];

  //retArray.push("5")
  items.map((item) => {
    retArray.push(item.nodeid);
  });

  //console.log("ARR: ", retArray)

  return retArray;
};

export const renderKamers = (
  woning: testdata1,
  verdiepen: testdata1[],
  kamers: testdata1[],
  uitgangen: testdata1[] = []
) => {
  let verdiepitems: kamerItem[] = [];

  //loop verdiepen
  verdiepen.map((verdiep) => {
    let VerdiepArray: kamerItem[] = [];
    // Maak een treeitem van elke kamer per verdiep
    let kamersOpVerdiep = isUitgangInKamer(kamers, verdiep.nodeid, []);
    // Maak een treeitem van elke uitgang per verdiep
    let uitgangenOpVerdiep = isUitgangInKamer(uitgangen, verdiep.nodeid, []);

    // Voeg een treeitem toe voor elke kamer op elk verdiep (VerdiepArray)
    VerdiepArray = voegToe(kamersOpVerdiep, VerdiepArray, uitgangen);

    //  Voeg een treeitem toe voor elke uitgang op elk verdiep (VerdiepArray)
    VerdiepArray = voegToe(uitgangenOpVerdiep, VerdiepArray, uitgangen);

    // Maak voor elk verdiep een treeitem en voeg er alle kamers en uitgangen aan toe (verdiepItems)
    const verdiepItems: kamerItem = {
      id: verdiep.nodeid,
      name: verdiep.label,
      key: verdiep.nodeid,
      //icon: getTreeIcon(verdiep.moduleType, verdiep.type),
      icon: getTreeIcon2(verdiep.typeIcon, verdiep.type),
      expanded: true,
      children: VerdiepArray,
    };

    verdiepitems.push(verdiepItems);
  });

  // Voeg uitgangen toe aan Root woning
  uitgangen.map((uitgang) => {
    if (uitgang.parent === woning.nodeid) {
      const woningUitgang: kamerItem = {
        id: uitgang.nodeid,
        name: uitgang.label,
        key: uitgang.nodeid,
        //icon: getTreeIcon(uitgang.moduleType, uitgang.type),
        icon: getTreeIcon2(uitgang.moduleType, uitgang.type),
        expanded: true,
        children: [],
      };
      verdiepitems.push(woningUitgang);
    }
  });

  // Maak een woning treeitem en voeg elk verdiep toe
  const retwoning: kamerItem = {
    id: woning.nodeid,
    name: woning.label,
    key: woning.nodeid,
    //icon: getTreeIcon(woning.moduleType, woning.type),
    icon: getTreeIcon2(woning.typeIcon, woning.type),
    expanded: true,
    children: verdiepitems,
  };

  return retwoning;
};

export function handleParentArray(parents: testdata1[] = []) {
  var sortedArray: testdata1[] = parents.sort(
    (n1, n2) => parseInt(n1.nodeid) - parseInt(n2.nodeid)
  );
  // firts item is woning
  // check second parent if 1 then add to woning
  let woning = sortedArray[0];
  let verdiepen = [];
  let kamers = [];
  let uitgangen = [];

  //console.log(parents)

  for (var treetype of sortedArray) {
    // uitgangen en verdiepen onder woning naar arrays (enkel onder id: 0)
    if (treetype.parent === woning.nodeid) {
      if (treetype.type === "woning") {
        verdiepen.push(treetype);
      } else {
        uitgangen.push(treetype);
      }
    } else {
      // uitgangen en kamers in elk verdiep naar arrays
      for (var verdiep of verdiepen) {
        if (treetype.parent === verdiep.nodeid) {
          if (treetype.type === "woning") {
            kamers.push(treetype);
          } else {
            uitgangen.push(treetype);
          }
        }
      }
    }
    // uitgangen in elke kamer naar array
    for (var kamer of kamers) {
      if (treetype.parent === kamer.nodeid) {
        if (treetype.type !== "woning") {
          uitgangen.push(treetype);
        }
      }
    }
  }
  // console.log(uitgangen)
  let kamerArray = renderKamers(woning, verdiepen, kamers, uitgangen);

  return kamerArray;
}

export function getItemsByCat(sortedArray: ios[], categoryId: string) {
  const retArray: ios[] = [];

  for (var treetype of sortedArray) {
    //console.log(sortedArray)
    if (treetype.nodeid === categoryId) {
      retArray.push(treetype);
    }
    if (treetype.parent === categoryId) {
      retArray.push(treetype);
    }
  }
  //console.log("ios: ", retArray)
  return retArray;
}
