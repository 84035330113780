import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Table } from "react-bootstrap";

import { getCustomerById } from "../database/userInfo";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import "./projects.css";
import { TextField, Input } from "@mui/material";

interface projects {
  id: number;
  userid: string;
  name: string;
  customerId: number;
}

interface customer {
  id: number;
  user_id: number;
  naam: string;
  voornaam: string;
}

interface projectFromDatabase {
  success: [
    {
      id: number;
      userid: string;
      name: string;
      customerId: number;
      createdAt: string;
      updatedAt: string;
    }
  ];
}

interface MyState {
  selectedRow: number;
  projlist: any;
  projToFind: string;
  klantToFind: string
}

interface MyProps {
  visible: string;
  projects: any;
  onDataChange: (t: any) => any;
  onOpenProject: (id: number) => any;
}

class Projects extends Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
    this.state = { selectedRow: 0, projlist: this.props.projects, projToFind: "", klantToFind: "" };
  }

  vstate = { settings: "hidden" };
  projToOpen: string = "0";
  projToOpenName = "";
  //projectToFind = "";

  handleClose = () => {
    this.vstate.settings = "hidden";
    this.props.onDataChange(this.vstate);
  };

  setName(id: number) {
    const p = this.props.projects;
    p.success.map((offerteItem: projects) => {
      if (offerteItem.id === id) {
        sessionStorage.setItem("bestandsnaam", offerteItem.name);
      }
    });
  }

  handleOpen = () => {
    this.vstate.settings = "hidden";
    this.props.onDataChange(this.vstate);
    this.props.onOpenProject(parseInt(this.projToOpen));
    this.setName(parseInt(this.projToOpen));
  };

  //selectedRow = 0

  setProject = (row: any) => {
    //console.log(row.target.parentNode.id)
    //this.selectedRow = row.target.parentNode.id
    this.projToOpen = row.target.parentNode.id;
    this.setState({ selectedRow: Number(row.target.parentNode.id) });
  };

  // Find custommer by ID in loaded Custommers database
  findCustommer = (id: number) => {
    let cus = JSON.parse(sessionStorage.getItem("DbCustommers") || "");
    const cust = cus.filter((custommer: customer) => custommer.id === id);

    return cust;
  };

  renderProjectsList(projectArray: projectFromDatabase) {
    const constpj: any = this.props.projects;

    if (this.state.projlist !== this.props.projects) {
      this.setState({ projlist: this.props.projects });
    }

    const handleChange = (event: any) => {
      //console.log(event.target);
      this.projToOpen = event.target.value;
      this.projToOpenName = event.target.label;
    };

    // Build projects array - Add custommer name

    let projects = [{ id: 0, project: "", custommer: "", creation: "", updated: "" }];
    projects.pop();

    if (projectArray.success) {
      projectArray.success.map((offerteItem) => {
        const id = offerteItem.id;
        const name = offerteItem.name;
        const createdAt = offerteItem.createdAt;
        const updatedAt = offerteItem.updatedAt;

        const createdTS1 = createdAt.replace('T', "_")
        const creationTime = createdTS1.substring(0, createdTS1.length - 5)

        const updatedTS = updatedAt.replace('T', "_")
        const updatedTime = updatedTS.substring(0, createdTS1.length - 5)

        const custommer = this.findCustommer(offerteItem.customerId);
        let c = "";
        if (custommer.length !== 0) {
          c = custommer[0].naam;
        }
        if (this.state.projToFind === "" && this.state.klantToFind === "") {
          // if proj and name are empty
          projects.push({ id: id, project: name, custommer: c, creation: creationTime, updated: updatedTime });
        } else if ((this.state.projToFind !== "" && name.includes(this.state.projToFind)) && this.state.klantToFind === "") {
          // If only proj to find
          projects.push({ id: id, project: name, custommer: c, creation: creationTime, updated: updatedTime });

        } else if ((this.state.klantToFind !== "" && c.includes(this.state.klantToFind)) && this.state.projToFind === "") {
          // If only klantToFind
          projects.push({ id: id, project: name, custommer: c, creation: creationTime, updated: updatedTime });
        } else if ((this.state.klantToFind !== "" && c.includes(this.state.klantToFind)) && (this.state.projToFind !== "" && name.includes(this.state.projToFind))) {
          projects.push({ id: id, project: name, custommer: c, creation: creationTime, updated: updatedTime });
        }

      });
    }

    const projectsList = () => {
      return (
        <>
          <Table striped bordered hover className="projectstable" >
            <thead>
              <tr>
                <th className={"projectsTableHeader"} >Datum</th>
                <th className={"projectsTableHeader"} style={{ width: '40%' }} >Project <br></br>
                  <TextField className={"projectsTableTextBox"} onChange={(e) => this.setFindProject(e.target.value)} defaultValue={this.state.projToFind}/></th>
                <th className={"projectsTableHeader"}>Klant  <br></br>
                <TextField  style={{ fontSize: '6px' }} onChange={(e) => this.setFindKlant(e.target.value)} defaultValue={this.state.klantToFind}/></th>
              </tr>
            </thead>

            {projects.map((offerteItem) => {
              return (
                <tr
                  id={String(offerteItem.id)}
                  onClick={this.setProject}

                  className={"clickable-row ".concat(
                    this.state.selectedRow === offerteItem.id ? "selected" : ""
                  )}
                >
                  <td className={"projectsTableRow"} >{offerteItem.creation}</td>
                  <td className={"projectsTableRow"}>{offerteItem.project}</td>
                  <td className={"projectsTableRow"}>{offerteItem.custommer}</td>
                </tr>
              );
            })}
          </Table>
        </>
      );
    };

    return projectsList();
  }

  setFindProject = (value: string) => {
    this.setState({projToFind: value});
  };

  setFindKlant = (value: string) => {
    this.setState({klantToFind: value});
  };

  render() {
    return (
      <Modal
        show={this.props.visible === "open" ? true : false}
        dialogClassName="modal_projects"
      >
        <Modal.Header closeButton onClick={this.handleClose}>
          <Modal.Title>
            <h1> Projects</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="projectsOptions">
          {this.renderProjectsList(this.state.projlist)}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={this.handleOpen}>
            Open project
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default Projects;
