import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

interface MyState {}

interface MyProps {
  visible: string;
  onDataChange: (t: any) => any;
  onSave: (t: any) => any;
}

class SaveAs extends Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
  }

  vstate = { settings: "hidden" };
  //fileName = "";

  handleClose = () => {
    this.vstate.settings = "hidden";
    this.props.onDataChange(this.vstate);
  };

  handleSave = () => {
    this.vstate.settings = "hidden";
    this.props.onSave(sessionStorage.getItem("bestandsnaam"));
    this.vstate.settings = "hidden";
    this.props.onDataChange(this.vstate);
    //sessionStorage.setItem('bestandsnaam', this.fileName);
  };

  handleTextChange = (event: any) => {
    //this.fileName = event.target.value;
    //sessionStorage.removeItem('projectId');
    sessionStorage.setItem('bestandsnaam', event.target.value);
  };

  render() {
    return (
      <Modal show={this.props.visible === "saveas" ? true : false}>
        <Modal.Header closeButton onClick={this.handleClose}>
          <Modal.Title>
            <h2> Opslaan</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Projectnaam: <br></br>
          <input type="text" onChange={this.handleTextChange} defaultValue={sessionStorage.getItem('bestandsnaam')||""} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={this.handleSave}>
            Bewaar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default SaveAs;
