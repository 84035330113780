import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";
import Nav from "react-bootstrap/Nav";
import { CardBody, CardHeader } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Form } from "react-bootstrap";
import Klantgegevens from "./klantgegevens";
import EindkKlantgegevens from "./eindklantgegevens";
import SettingsOptions from "./settingsOptions";
import "./settings.css";
import { CurrencyRuble, WidthWide } from "@mui/icons-material";

interface MyState {
  selectedView: string;
}

interface MyProps {
  visible: string;
  onDataChange: (t: any) => any;
  onPakettenChange: (t: any) => any;
  paketten: boolean;
  onREL04_08Changed: (t: any) => any;
  rel04_08: boolean;
}

class Settings extends Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
    this.state = { selectedView: "Installateur" };
  }

  vstate = { settings: "hidden" };

  handleClose = () => {
    this.vstate.settings = "hidden";
    //this.props.onDataChange(false)
    this.props.onDataChange(this.vstate);
  };

  handleSave = () => {
    const currentView = this.state.selectedView;
    console.log(currentView);
    this.vstate.settings = "hidden";
    //this.props.onDataChange(false)
    this.props.onDataChange(this.vstate);
  };

  handleSelect = (eventKey: any) => {
    if (`${eventKey}` === "Installateur") {
      this.setState({ selectedView: "Installateur" });
    } else if (`${eventKey}` === "Klant") {
      this.setState({ selectedView: "Klant" });
    } else if (`${eventKey}` === "Opties") {
      this.setState({ selectedView: "Opties" });
    }
  };

  onPakettenChanged = (paketten: boolean) => {
    this.props.onPakettenChange(paketten);
  };

  onREL04_08Changed = (rel04_08: boolean) => {
    this.props.onREL04_08Changed(rel04_08);
  };

  render() {
    return (
      <Modal
        show={this.props.visible === "settings" ? true : false}
        dialogClassName="modal_settings"
      >
        <Modal.Header closeButton onClick={this.handleClose}>
          <Modal.Title>
            <h1> Instellingen</h1>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="body_settings">
          <Card>
            <CardHeader className="card_settings_header">
              <Nav className="card_settings-nav" activeKey={this.state.selectedView} onSelect={this.handleSelect}>
                <Nav.Item>
                  <Nav.Link
                    className="card_settings-nav-link"
                    eventKey="Installateur"
                  >
                    Installateur
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className="card_settings-nav-link" eventKey="Klant">
                    Klant
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className="card_settings-nav-link"
                    eventKey="Opties"
                  >
                    Opties
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </CardHeader>

            <CardBody className="card_settings">
              {this.state.selectedView === "Installateur" ? (
                <Klantgegevens />
              ) : (
                ""
              )}
              {this.state.selectedView === "Klant" ? (
                <EindkKlantgegevens />
              ) : (
                ""
              )}
              {this.state.selectedView === "Opties" ? (
                <SettingsOptions
                  paketten={this.props.paketten}
                  onPakettenChanged={this.onPakettenChanged}
                  rel04_08={this.props.rel04_08}
                  onREL04_08Changed={this.onREL04_08Changed}
                />
              ) : (
                ""
              )}
            </CardBody>
          </Card>
        </Modal.Body>
      </Modal>
    );
  }
}

export default Settings;
