import React, { Component } from "react";
import { Button, Form, Col, Row } from "react-bootstrap";
import {
  GetCustomers,
  getCustomerById,
  UpdateCustomer,
  HandleNewCustomer,
} from "../database/userInfo";
import "./eindklantgegevens.css";

import Klant from "./klant";

interface klant {
  id: any;
  naam: string;
  voornaam: string;
  firma: string;
  straat: string;
  huisnummer: string;
  postcode: string;
  gemeente: string;
  land: string;
  telefoon: string;
  btw: string;
}

interface customerText {
  id: number;
  displayText: string;
}

interface customers {
  success: [
    {
      id: number;
      user_id: number;
      naam: string;
      voornaam: string;
      firma: string;
      straat: string;
      huisnr: string;
      postcode: string;
      gemeente: string;
      land: string;
      telefoon: string;
      btwnr: string;
    }
  ];
}

interface customer {
  id: number;
  user_id: number;
  naam: string;
  voornaam: string;
  firma: string;
}

interface customerText {
  id: number;
  displayText: string;
}

interface MyState {
  klant: klant;

  customers: Array<customer>;
  newCustomer: boolean;
  renderForm: boolean;
  getCustomersCmd: boolean;
}
interface MyProps {
  //onDataChange: (t: any) => any;
}

class EindkKlantgegevens extends Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
    this.state = {
      newCustomer: false,
      klant: {
        id: "",
        naam: "",
        voornaam: "",
        firma: "",
        straat: "",
        huisnummer: "",
        postcode: "",
        gemeente: "",
        land: "",
        telefoon: "",
        btw: "",
      },
      customers: [],
      renderForm: false,
      getCustomersCmd: true,
    };
    this.getCustomers = this.getCustomers.bind(this);
    this.getCustomersById = this.getCustomersById.bind(this);
  }

  naam = "1";

  async getCustomers() {
    GetCustomers().then((data: customers) => {
      const userInfo = data.success;
      let klantArray: Array<customer> = [];

      userInfo.map((customer: any) => {
        //console.log(customer)
        const cust = {
          id: customer.id,
          user_id: customer.user_id,
          voornaam: customer.voornaam,
          naam: customer.naam,
          firma: customer.firma,
          straat: customer.straat,
          huisnummer: customer.huisnummer,
          postcode: customer.postcode,
          gemeente: customer.gemeente,
          land: customer.land,
          telefoon: customer.telefoon,
          btw: customer.btw,
        };
        klantArray.push(cust);
      });
      //console.log(this.state.customers)
      //console.log(klantArray)
      if (this.state.getCustomersCmd === true) {
        this.setState({ customers: klantArray });
        this.setState({ getCustomersCmd: false });
      }

      // Update custommers array when new cust is added
      if (this.state.customers.length !== klantArray.length) {
        //console.log(klantArray)
        this.setState({ customers: klantArray });
      }
    });
  }

  async getCustomersById(id: string) {
    let rKlant: klant = {
      id: "0",
      naam: "",
      voornaam: "",
      firma: "",
      straat: "",
      huisnummer: "",
      postcode: "",
      gemeente: "",
      land: "",
      telefoon: "",
      btw: "",
    };

    const setKlant = sessionStorage.getItem("eindklantId") || "0";

    if (id !== "0") {
      //debugger
      getCustomerById(parseInt(id)).then((data: any) => {
        //console.log(data)
        const userInfo = data.success[0];
        //if (this.state.naam !== userInfo.naam) {

        rKlant = {
          id: userInfo.id,
          naam: userInfo.naam,
          voornaam: userInfo.voornaam,
          firma: userInfo.firma,
          straat: userInfo.straat,
          huisnummer: userInfo.huisnummer,
          postcode: userInfo.postcode,
          gemeente: userInfo.gemeente,
          land: userInfo.land,
          telefoon: userInfo.telefoon,
          btw: userInfo.btw,
        };

        // if (parseInt(setKlant) !== userInfo.id) {
        this.setState({ klant: rKlant });
        // }

        sessionStorage.setItem(
          "eindklant",
          userInfo.naam + " " + userInfo.voornaam
        );
        sessionStorage.setItem("eindklantId", userInfo.id);

        sessionStorage.setItem("eindklantIdSelected", userInfo);

      });
    } else {
      sessionStorage.setItem("eindklantId", "0")
      this.setState({ klant: rKlant });
      
    }
  }


  newCustomer = () => {
    this.setState({ newCustomer: true });
    this.setState({
      klant: {
        id: "",
        naam: "",
        voornaam: "",
        firma: "",
        straat: "",
        huisnummer: "",
        postcode: "",
        gemeente: "",
        land: "",
        telefoon: "",
        btw: "",
      },
    });

    this.setState({ renderForm: true });
  };

  async saveNew(
    id: any,
    naam: string,
    voornaam: string,
    firma: string,
    straat: string,
    huisnr: string,
    postcode: string,
    gemeente: string,
    land: string,
    telefoon: string,
    btwnr: string
  ) {
    //const created = await HandleNewCustomer(
    //debugger
    const created = await UpdateCustomer(
      '',
      naam,
      voornaam,
      firma,
      straat,
      huisnr,
      postcode,
      gemeente,
      land,
      telefoon,
      btwnr
    );
   // console.log("rp: ", created);

    //if (created !== null) {
      const data = created?.data.data;
      const newId = data?.id

      if (newId) {
        sessionStorage.setItem("eindklantId", newId)
        this.setState({ klant: data });
      }

   // }
    this.setState({ getCustomersCmd: true });
    this.getCustomers();
    if (newId) {
    let rKlant = data;

    this.setState({ klant: rKlant });

    sessionStorage.setItem("eindklant", rKlant.naam + " " + rKlant.voornaam);
    sessionStorage.setItem("eindklantId", rKlant.id);
  }
    //this.setState({ newCustomer: false });
    //this.setState({ renderForm: false });

    //this.renderDropdown();
    //this.renderForm();
  }

  // Bewaar klant
  submitHandler = (e: any) => {
    //debugger
    e.preventDefault();

    const id = e.currentTarget.id.value;
    const naam = e.currentTarget.naam.value;
    const voornaam = e.currentTarget.voornaam.value;
    const firma = e.currentTarget.firma.value;
    const straat = e.currentTarget.straat.value;
    const huisnr = e.currentTarget.huisnummer.value;
    const postcode = e.currentTarget.postcode.value;
    const gemeente = e.currentTarget.gemeente.value;
    const land = e.currentTarget.land.value;
    const telefoon = e.currentTarget.telefoon.value;
    const btwnr = e.currentTarget.btw.value;

    this.saveNew(
      id,
      naam,
      voornaam,
      firma,
      straat,
      huisnr,
      postcode,
      gemeente,
      land,
      telefoon,
      btwnr
    );
  };

  renderDropdown() {
    const pa = this.state.customers;
    //console.log(pa);
    //let cust = [{ value: 999, key: 999, naam: "Kies een klant", voornaam: "" }];
    let cust: Array<customerText> = [
      { id: 0, displayText: "Maak een nieuwe klant" },
    ];

    
      pa.map((offerteItem) => {
        let displayText = "";

        if (offerteItem.firma !== "") {
          displayText = offerteItem.firma + " - ";
        }
        if (offerteItem.naam !== "") {
          displayText = displayText + offerteItem.naam;

          if (offerteItem.voornaam !== "") {
            displayText = displayText + " " + offerteItem.voornaam;
          } else {
            displayText = displayText;
          }
        }

        cust.push({
          id: offerteItem.id,
          displayText: displayText,
        });
      });
    
    const klantId = sessionStorage.getItem("eindklantId") || "0";
    if (klantId !== "0") {
    }

    const pl: any = () => {
      const handleCustomerChange = (event: any) => {
        this.getCustomersById(event.target.value);
      };
      return (
        <Form.Select
          aria-label="Default select example"
          onChange={handleCustomerChange}
          key={"KlantenDropdown"}
          value={klantId}
        >
          {cust.map((offerteItem) => {
            return (
              <option value={offerteItem.id} key={offerteItem.id}>
                {offerteItem.displayText}
              </option>
            );
          })}
        </Form.Select>
      );
    };

    const kl = this.state.klant.id;
    if (kl === "") {
      this.getCustomersById(klantId);
    }
    return pl();
  }

  renderNew() {
    const newBtn = () => {
      return (
        <p>
          <Button variant="primary" onClick={this.newCustomer}>
            Maak een nieuwe klant aan
          </Button>
        </p>
      );
    };
    return newBtn();
  }

  updateKlant = (klant: klant) => {
    //console.log(klant)
    this.saveNew(
      klant.id,
      klant.naam,
      klant.voornaam,
      klant.firma,
      klant.straat,
      klant.huisnummer,
      klant.postcode,
      klant.gemeente,
      klant.land,
      klant.telefoon,
      klant.btw
    );
  };

  render() {
    //this.renderProkectsList()
    this.getCustomers();
    return (
      <>
        {this.renderDropdown()}

        <Klant onKlantUpdate={this.updateKlant} klant={this.state.klant} />
      </>
    );
  }
}

export default EindkKlantgegevens;
