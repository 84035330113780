//import * as interfaces from './sharedInterfaces';
import * as interfaces from "./../Opbouw/Overzicht/sharedInterfaces";
import * as dbFunctions from "./modules";
import * as userDbFunctions from "./userInfo";
import { GetOutputsFromDatabase } from "./items";


interface OutputModules {
  nodeid: string;
  label: string;
  type: string;
  parent: string;
  typeIcon: string;
  moduleType: string;
}

// opbouw - modules - modulesfunctions
export async function getModules() {
  const retrievedModules = await dbFunctions
    .getModulesFromDatabase()
    .then((modules: any) => {
      //console.log("getModules2",modules.success)
      const dbMods = modules.success;

      let retModules: interfaces.ModuleTypes[] = [];

      dbMods.forEach((mod: any) => {
        const mods: interfaces.ModuleTypes = {
          id: mod.id,
          type: mod.type,
          name: mod.name,
          outputs: mod.outputs,
          nextModule: mod.nextModule,
          parentModule: mod.parentModule,
          inputs: mod.inputs,
          busConsumption: mod.busConsumption,
          adresses: mod.adresses,
          din: mod.din,
          icon: mod.icon,
          prijs: mod.prijs,
          offCat: mod.offCat,
          sa: mod.sa,
          pakket: mod.pakket,
          //totoutp: mod.totoutp,
        };
        retModules.push(mods);
      });

      sessionStorage.setItem("DbModules", JSON.stringify(retModules));
      return retModules;
    });

  await dbFunctions
    .getExtendedModuleFromDatabase()
    .then((modules: any) => {
      //console.log("getModules2",modules.success)
      const dbMods = modules.success;

      let retModulesExtended: interfaces.ExtendedModuleTypes[] = [];

      dbMods.forEach((mod: any) => {
        const mods: interfaces.ExtendedModuleTypes = {
          id: mod.id,
          module_id: mod.module_id,
          beschrijving: mod.beschrijving,
          korteBeschrijving: mod.korteBeschrijving,
          url: mod.url,
          prijs: mod.prijs,
          offCat: mod.offCat,
        };
        retModulesExtended.push(mods);
      });

      sessionStorage.setItem(
        "DbExtendedModuleTypes",
        JSON.stringify(retModulesExtended)
      );
      return retModulesExtended;
    });

  return retrievedModules;
}

async function getInputs() {
  const inp = await GetOutputsFromDatabase("ingangen").then(
    (outputmodulesDB: any) => {
      const dbMods = outputmodulesDB.success;

      let outputs: OutputModules[] = [];

      dbMods.forEach((mod: any) => {
        const mods: OutputModules = {
          nodeid: mod.nodeid,
          label: mod.label,
          type: mod.type,
          parent: mod.parent,
          typeIcon: mod.typeIcon,
          moduleType: mod.moduleType,
        };
        outputs.push(mods);
      });
      sessionStorage.setItem("modulesInputs", JSON.stringify(outputs));
      return outputs;
    }
  );
  return inp;
}

interface customer {
  id: number;
  user_id: number;
  naam: string;
  voornaam: string;
}
/*
interface customers {
  success: [
    {
      id: number;
      user_id: number;
      naam: string;
      voornaam: string;
      firma: string;
      straat: string;
      huisnr: string;
      postcode: string;
      gemeente: string;
      land: string;
      telefoon: string;
      btwnr: string;
    }
  ];
}
*/
// Load items for uitgangen / ingangen menu tree
async function getOutputs() {
  const outp = await GetOutputsFromDatabase("uitgangen").then(
    (outputmodulesDB: any) => {
      const dbMods = outputmodulesDB.success;

      let outputs: OutputModules[] = [];

      dbMods.forEach((mod: any) => {
        const mods: OutputModules = {
          nodeid: mod.nodeid,
          label: mod.label,
          type: mod.type,
          parent: mod.parent,
          typeIcon: mod.typeIcon,
          moduleType: mod.moduleType,
        };
        outputs.push(mods);
      });
      //console.log(outputs)
      sessionStorage.setItem("modulesOutputs", JSON.stringify(outputs));
      return outputs;
    }
  );

  return outp;

  //console.log("GET: ", outp)
  //this.setState({ outputs: outp });
  //this.outputItems = outp;

  //this.setState({ inputs: inp });
  //this.inputItems = inp;
}

async function getCustommers() {
  const custommers = await userDbFunctions
    .GetCustomers()
    .then((custommers: any) => {
      const clients = custommers.success;

      let retCustommers: any[] = [];

      clients.forEach((client: any) => {
        const clients: customer = {
          id: client.id,
          user_id: client.user_id,
          naam: client.naam,
          voornaam: client.voornaam,
        };
        retCustommers.push(clients)
      });
      return retCustommers

    });
    sessionStorage.setItem(
      "DbCustommers",
      JSON.stringify(custommers)
    );
  return custommers
    
}

export async function loadDatabases() {
  //const projects = new Promise<any>((resolve, reject) => {
  //const  getModules()

  await getOutputs();
  await getInputs();
   await getModules();
  await getCustommers();
  //console.log(o)
  //resolve (true)
  //  });
  return true;
}
